import React from 'react';

import cx from 'classnames';
import { SocialIcon } from 'react-social-icons';
import Layout from '../components/layout';
import styles from './Index.module.scss';
import SEO from '../components/seo';

const iconProps = {
  className: styles.icon,
  target: '_blank',
  style: {
    height: 48,
    width: 48,
  },
};

const Icon = ({
  href, className, children, style, target,
}) => (
  <a
    className={cx(styles.iconLink, styles.icon)}
    href={href}
    style={style}
    target={target}
  >
    <div
      className={cx(styles.customIcon, className)}
    >
      {children}
    </div>
  </a>
);

const AplozzIcon = (props) => (
  <Icon
    {...props}
    href="https://aplozz.com/"
    className={styles.aplozz}
    children={<>A</>}
  />
);

const ResourceIcon = (props) => (
  <Icon
    {...props}
    href="https://www.resource.io/"
    className={styles.resource}
    children={<div className={styles.resourceIcon} />}
  />
);

const GoogleIcon = (props) => (
  <Icon
    {...props}
    href="https://www.google.com/"
    className={styles.google}
    children={<div className={styles.googleIcon} />}
  />
);

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className={styles.container}>
      <div className={styles.name}>Marcus Lowe</div>
      <div className={styles.header}>current:</div>
      <div className={styles.icons}>
        <AplozzIcon {...iconProps} />
        <SocialIcon url="https://twitter.com/TheMarcusLowe" {...iconProps} />
        <SocialIcon url="https://www.reddit.com/user/marcusl92" {...iconProps} />
      </div>
      <div className={styles.header}>previous:</div>
      <div className={styles.icons}>
        <ResourceIcon {...iconProps} />
        <GoogleIcon {...iconProps} />
      </div>
    </div>
  </Layout>
);

export default IndexPage;
